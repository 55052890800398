import ApiService from "api/ApiService";
import unisotBgImage from "assets/img/login.jpeg";
import ibmBgImage from "assets/img/ibmBg.jpg";
import unisotLogo from "assets/img/unisot-logo-horizontal.png";
import ibmLogo from "assets/img/ibmLogoWhite.png";
import unisotMiniLogo from "assets/img/miniLogo.png";

const bgImage = {
  unisot: unisotBgImage,
  ibm: ibmBgImage,
};

const logo = {
  unisot: unisotLogo,
  unisotMini: unisotMiniLogo,
  ibm: ibmLogo,
};

export const getBgImage = () => {
  switch (ApiService.theme) {
    case ApiService.themes.unisot:
      return bgImage.unisot;
    case ApiService.themes.ibm:
      return bgImage.ibm;
    default:
      break;
  }
};

export const getMiniLogo = () => {
  switch (ApiService.theme) {
    case ApiService.themes.unisot:
      return logo.unisotMini;
    case ApiService.themes.ibm:
      return logo.ibm;
    default:
      break;
  }
};

export const getLogo = () => {
  switch (ApiService.theme) {
    case ApiService.themes.unisot:
      return logo.unisot;
    case ApiService.themes.ibm:
      return logo.ibm;
    default:
      break;
  }
};

const CompanyTheme = {
  getBgImage,
  getLogo,
  getMiniLogo,
};

export default CompanyTheme;

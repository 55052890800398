import i18n from "i18next";
import ErrorPage from "views/Pages/ErrorPage.js";
import RedirectPage from "views/Pages/RedirectPage";

// @material-ui/icons
import Image from "@material-ui/icons/Image";
import ForwardIcon from "@material-ui/icons/Forward";

let currentLanguage;
let renderedRoutes;

const getNavigationRoutes = () => {
  if (i18n.language !== currentLanguage) {
    currentLanguage = i18n.language;
    renderedRoutes = [
      {
        hidden: true,
        collapse: true,
        name: i18n.t("pages"),
        rtlName: i18n.t("pages"),
        icon: Image,
        state: "pageCollapse",
        views: [
          {
            path: "/resolve",
            name: i18n.t("error-page"),
            rtlName: i18n.t("error-page"),
            mini: "E",
            rtlMini: "E",
            component: ErrorPage,
            layout: "/auth",
          },
          {
            path: "/redirect-page",
            name: i18n.t("redirect-page"),
            rtlName: i18n.t("redirect-page"),
            icon: ForwardIcon,
            component: RedirectPage,
            layout: "/auth",
          },
        ],
      },
    ];
  }
  return renderedRoutes;
};

export default getNavigationRoutes;

import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import ApiService from "api/ApiService.js";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      try {
        const organizations = await ApiService.getAvailableOrganizations(
          signal
        );
        const subdomainsMapping = {};
        // eslint-disable-next-line no-unused-vars
        for (const organization of organizations) {
          if (!!organization.subdomains && !!organization.landingPageUri) {
            const subdomains = organization.subdomains.split(",");
            // eslint-disable-next-line no-unused-vars
            for (const subdomain_ of subdomains) {
              const subdomain = subdomain_.trim();
              if (subdomain.length > 0) {
                subdomainsMapping[subdomain] = organization.landingPageUri;
              }
            }
          }
        }
        const domainParts = window.location.hostname.split(".");
        const subdomain = domainParts.slice(0, -2).join(".");
        if (subdomainsMapping.hasOwnProperty(subdomain)) {
          window.location.replace(subdomainsMapping[subdomain]);
        } else {
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          {loading ? (
            <h2 className={classes.subTitle}>{t("loading")}</h2>
          ) : (
            <React.Fragment>
              <h1 className={classes.title}>404</h1>
              <h2 className={classes.subTitle}>{t("page-not-found")}</h2>
              <h4 className={classes.description}>
                {t("page-not-found-error")}
              </h4>
            </React.Fragment>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}

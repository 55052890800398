import i18n from "i18next";

const tokenStorage = {
  cookie: "COOKIE",
  localStorage: "LOCAL_STORAGE",
};
let tokenStorageType = tokenStorage.localStorage;

const logoText = "UNISOT";
const logoURL = "https://unisot.com";

const production = true;

let baseURL = "http://localhost:4000/api";
const elkURL = "https://elk.unisot.id/api";
if (production) {
  baseURL = "https://idproduction.unisot.id/api";
}

const version = "1.0.0";

const themes = {
  unisot: "UNISOT",
  ibm: "IBM",
};

const theme = themes.unisot;

const languageTranslations = [
  { id: "en", token: "english" },
  { id: "es", token: "spanish" },
  { id: "de", token: "german" },
  { id: "zh", token: "chinese" },
];

const methods = {
  get: "GET",
  post: "POST",
  delete: "DELETE",
  patch: "PATCH",
};

const contentTypes = {
  json: "application/json",
  formData: "form-data",
  searchParams: "search-parameters",
  fileDownload: "file-download",
};

const urlType = {
  id: "ID SERVICE",
};

const endpoints = {
  getAvailableOrganizations: {
    method: methods.get,
    path: "users/availableOrganizations",
    status: 200,
    urlType: urlType.id,
  },
};

const localStorageSessionTokenKey = "userIdToken";

const getSessionToken = () => {
  const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
  if (sessionToken) {
    return sessionToken;
  }
  throw new Error(i18n.t("session-token-does-not-exist"));
};

const storeSessionToken = (token) => {
  if (tokenStorageType === tokenStorage.localStorage) {
    localStorage.setItem(localStorageSessionTokenKey, token);
  }
};

const populatePath = (urlPath, params) => {
  let populatedPath = urlPath;
  const processingParameters = Object.entries(params);
  const paramsToProcess = {};
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of processingParameters) {
    const token = `{{${key}}}`;
    if (urlPath.includes(token)) {
      populatedPath = populatedPath.split(token).join(value);
    } else {
      paramsToProcess[key] = value;
    }
  }
  return {
    populatedPath,
    paramsToProcess,
  };
};

const apiRequest = async (
  endpoint,
  signal = undefined,
  params = {},
  authorize = true,
  exportSessionToken = false,
  overrideUrl = undefined
) => {
  const { method, path, status, populate } = endpoint;
  let populatedPath = path;
  let paramsToProcess = params;
  if (populate) {
    const pathPopulatingResult = populatePath(path, params);
    populatedPath = pathPopulatingResult.populatedPath;
    paramsToProcess = pathPopulatingResult.paramsToProcess;
  }

  // Construct API URL
  const serviceUrl = endpoint.urlType === urlType.elk ? elkURL : baseURL;
  const url = new URL(
    overrideUrl ? overrideUrl : `${serviceUrl}/${populatedPath}`
  );

  // Set method
  const request = {
    method,
  };
  if (signal) {
    request.signal = signal;
  }

  // Set headers
  const headers = {};
  if (endpoint.contentType) {
    if (endpoint.contentType === contentTypes.json) {
      headers["Content-Type"] = endpoint.contentType;
      request.body = JSON.stringify(paramsToProcess);
    } else if (endpoint.contentType === contentTypes.formData) {
      const formData = new FormData();
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(paramsToProcess)) {
        formData.append(key, value);
      }
      request.body = formData;
    } else if (
      endpoint.contentType === contentTypes.searchParams ||
      endpoint.contentType === contentTypes.fileDownload
    ) {
      url.search = new URLSearchParams(paramsToProcess).toString();
    }
  }
  if (tokenStorageType === tokenStorage.cookie) {
    request.credentials = "include";
  } else if (tokenStorageType === tokenStorage.localStorage) {
    if (authorize) {
      const sessionToken = getSessionToken();
      headers["authorization"] = `Bearer ${sessionToken}`;
    }
  }
  if (Object.keys(headers).length > 0) {
    request.headers = headers;
  }

  const response = await fetch(url, request);
  let responseData;
  if (
    response.status === status &&
    endpoint.contentType === contentTypes.fileDownload
  ) {
    responseData = await response.blob();
  } else {
    responseData = await response.json();
  }
  if (response.status !== status) {
    throw new Error(responseData.message);
  }
  if (exportSessionToken) {
    storeSessionToken(responseData.token);
  }
  return responseData;
};

const ApiService = {
  storeSessionToken,
  logoText,
  logoURL,
  languageTranslations,
  version,
  theme,
  themes,
  getAvailableOrganizations: async (signal = undefined) => {
    const params = {};
    const responseData = await apiRequest(
      endpoints.getAvailableOrganizations,
      signal,
      params,
      false
    );
    return responseData;
  },
};

export default ApiService;

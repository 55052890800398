/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #CFAF4E => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################

import ApiService from "api/ApiService";

const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

const drawerMiniWidth = 80;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const containerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "&:before,&:after": {
    display: "table",
    content: '" "',
  },
  "&:after": {
    clear: "both",
  },
};

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "@media (min-width: 768px)": {
    width: "750px",
  },
  "@media (min-width: 992px)": {
    width: "970px",
  },
  "@media (min-width: 1200px)": {
    width: "1170px",
  },
  "&:before,&:after": {
    display: "table",
    content: '" "',
  },
  "&:after": {
    clear: "both",
  },
};

let defaultFont = {
  fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em",
};
let white = ["ffffff"];
let primaryColor = ["#CFAF4E", "#CFAF4E", "#CFAF4E", "#CFAF4E", "#CFAF4E"];
let warningColor = [
  "#4e667f",
  "#55708b",
  "#4e667f",
  "#55708b",
  "#475c73",
  "#ecf0f3",
  "#f8fafb",
];
let dangerColor = [
  "#D1583C",
  "#D1583C",
  "#D1583C",
  "#D1583C",
  "#D1583C",
  "#D1583C",
  "#D1583C",
];
let successColor = [
  "#019062",
  "#01a36f",
  "#019062",
  "#01a36f",
  "#017d55",
  "#e0fff5",
  "#f3fffb",
];
let infoColor = [
  "#062b51",
  "#062b51",
  "#083463",
  "#062b51",
  "#05213f",
  "#A6BDCB",
  "#A6BDCB",
];
let roseColor = ["#024266", "#024266", "#024266", "#024266", "#024266"];
let grayColor = [
  "#8dabbd",
  "#777",
  "#333",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#555555",
  "#333",
  "#eee",
  "#ccc",
  "#e4e4e4",
  "#E5E5E5",
  "#f9f9f9",
  "#f5f5f5",
  "#495057",
  "#e7e7e7",
  "#212121",
  "#c8c8c8",
  "#505050",
];

let greenColor = "#044758";
let turqColor = "#F0F4F6";

let blackColor = "#000000";
let whiteColor = "#FFFFFF";
let buttonTextColor = "#fff";

let twitterColor = "#55acee";
let facebookColor = "#3b5998";
let googleColor = "#dd4b39";
let linkedinColor = "#0976b4";
let pinterestColor = "#cc2127";
let youtubeColor = "#e52d27";
let tumblrColor = "#35465c";
let behanceColor = "#1769ff";
let dribbbleColor = "#ea4c89";
let redditColor = "#ff4500";

if (ApiService.theme === "IBM") {
  defaultFont = {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em",
  };

  white = ["ffffff"];
  primaryColor = ["#0f62fe", "#0f62fe", "#0f62fe", "#0f62fe", "#0f62fe"];
  warningColor = [
    "#da1e28",
    "#da1e28",
    "#da1e28",
    "#da1e28",
    "#da1e28",
    "#da1e28",
    "#da1e28",
  ];
  dangerColor = [
    "#d02670",
    "#d02670",
    "#d02670",
    "#d02670",
    "#d02670",
    "#d02670",
    "#d02670",
  ];
  successColor = [
    "#198038",
    "#198038",
    "#198038",
    "#198038",
    "#198038",
    "#198038",
    "#198038",
  ];
  infoColor = [
    "#0072c3",
    "#0072c3",
    "#0072c3",
    "#0072c3",
    "#0072c3",
    "#0072c3",
    "#0072c3",
  ];
  roseColor = ["#525252", "#525252", "#525252", "#525252", "#525252"];
  grayColor = [
    "#8f8b8b",
    "#726e6e",
    "#726e6e",
    "#726e6e",
    "#cac5c4",
    "#cac5c4",
    "#cac5c4",
    "#726e6e",
    "#cac5c4",
    "#cac5c4",
    "#cac5c4",
    "#cac5c4",
    "#cac5c4",
    "#525252",
    "#525252",
    "#cac5c4",
    "#cac5c4",
    "#cac5c4",
    "#cac5c4",
  ];

  greenColor = "#044317";
  turqColor = "#edf5ff";

  blackColor = "#000000";
  whiteColor = "#FFFFFF";
  buttonTextColor = "#FFFFFF";
}

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
};

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(primaryColor[0]) +
    ",.4)",
};
const infoBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(infoColor[0]) +
    ",.4)",
};
const successBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(successColor[0]) +
    ",.4)",
};
const warningBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(warningColor[0]) +
    ",.4)",
};
const grayBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(grayColor[0]) +
    ",.4)",
};
const dangerBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(dangerColor[0]) +
    ",.4)",
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(roseColor[0]) +
    ",.4)",
};

const greenBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(greenColor) +
    ",.4)",
};

const turqBoxShadow = {
  boxShadow: "none",
};
const warningCardHeader = {
  background:
    "linear-gradient(60deg, " + warningColor[1] + ", " + warningColor[2] + ")",
  ...warningBoxShadow,
};
const grayCardHeader = {
  background:
    "linear-gradient(60deg, " + grayColor[0] + ", " + grayColor[1] + ")",
  ...grayBoxShadow,
};
const successCardHeader = {
  background:
    "linear-gradient(60deg, " + successColor[1] + ", " + successColor[2] + ")",
  ...successBoxShadow,
};
const dangerCardHeader = {
  background:
    "linear-gradient(60deg, " + dangerColor[1] + ", " + dangerColor[2] + ")",
  ...dangerBoxShadow,
};
const infoCardHeader = {
  background:
    "linear-gradient(60deg, " + infoColor[1] + ", " + infoColor[2] + ")",
  ...infoBoxShadow,
};
const primaryCardHeader = {
  background:
    "linear-gradient(60deg, " + primaryColor[1] + ", " + primaryColor[2] + ")",
  ...primaryBoxShadow,
};
const roseCardHeader = {
  background:
    "linear-gradient(60deg, " + roseColor[1] + ", " + roseColor[2] + ")",
  ...roseBoxShadow,
};

const greenCardHeader = {
  background: "linear-gradient(60deg, " + greenColor + ", " + greenColor + ")",
  ...greenBoxShadow,
};

const turqCardHeader = {
  background: "linear-gradient(60deg, " + turqColor + ", " + turqColor + ")",
  ...turqBoxShadow,
};

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
  borderRadius: "6px",
  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
  background: whiteColor,
};

const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid " + grayColor[8],
  height: "auto",
  ...defaultFont,
};

const cardHeader = {
  margin: "15px 15px 0",
  borderRadius: "3px",
  padding: "15px",
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 3px 20px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s",
};

const tooltip = {
  padding: "10px 15px",
  minWidth: "130px",
  color: whiteColor,
  lineHeight: "1.7em",
  background: "rgba(" + hexToRgb(grayColor[6]) + ",0.9)",
  border: "none",
  borderRadius: "3px",
  opacity: "1!important",
  boxShadow:
    "0 8px 10px 1px rgba(" +
    hexToRgb(blackColor) +
    ", 0.14), 0 3px 14px 2px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 5px 5px -3px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
  maxWidth: "200px",
  textAlign: "center",
  fontFamily: '"Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif',
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  textShadow: "none",
  textTransform: "none",
  letterSpacing: "normal",
  wordBreak: "normal",
  wordSpacing: "normal",
  wordWrap: "normal",
  whiteSpace: "normal",
  lineBreak: "auto",
};

const title = {
  color: grayColor[2],
  textDecoration: "none",
  fontWeight: "300",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: "'Montserrat','Roboto', 'Helvetica', 'Arial', sans-serif",
  "& small": {
    color: grayColor[1],
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1",
  },
};

const cardTitle = {
  ...title,
  marginTop: "0",
  marginBottom: "3px",
  minHeight: "auto",
  "& a": {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto",
  },
};

const cardSubtitle = {
  marginTop: "-.375rem",
};

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem",
  },
};

export {
  hexToRgb,
  //variables
  drawerWidth,
  drawerMiniWidth,
  transition,
  container,
  containerFluid,
  boxShadow,
  card,
  defaultFont,
  white,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  greenColor,
  turqColor,
  blackColor,
  whiteColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  grayCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  greenCardHeader,
  turqCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  tooltip,
  title,
  cardTitle,
  cardSubtitle,
  cardLink,
  buttonTextColor,
};
